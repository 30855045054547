exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cats-tsx": () => import("./../../../src/pages/cats.tsx" /* webpackChunkName: "component---src-pages-cats-tsx" */),
  "component---src-pages-euro-2024-tsx": () => import("./../../../src/pages/euro-2024.tsx" /* webpackChunkName: "component---src-pages-euro-2024-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoice-generator-tsx": () => import("./../../../src/pages/invoice-generator.tsx" /* webpackChunkName: "component---src-pages-invoice-generator-tsx" */),
  "component---src-pages-playground-tsx": () => import("./../../../src/pages/playground.tsx" /* webpackChunkName: "component---src-pages-playground-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-2024-wrapped-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/2024-wrapped.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-2024-wrapped-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-being-the-change-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/being-the-change.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-being-the-change-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-career-changes-whats-new-in-2021-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/career-changes-whats-new-in-2021.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-career-changes-whats-new-in-2021-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-circle-audio-player-snippet-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/circle-audio-player-snippet.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-circle-audio-player-snippet-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-dipping-into-tech-lead-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/dipping-into-tech-lead.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-dipping-into-tech-lead-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-euro-2024-generator-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/euro-2024-generator.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-euro-2024-generator-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-exercise-and-me-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/exercise-and-me.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-exercise-and-me-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-invoice-generator-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/invoice-generator.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-invoice-generator-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-kindness-costs-nothing-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/kindness-costs-nothing.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-kindness-costs-nothing-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-netlify-lambda-greenhouse-api-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/netlify-lambda-greenhouse-api.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-netlify-lambda-greenhouse-api-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-new-dark-mode-toggler-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/new-dark-mode-toggler.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-new-dark-mode-toggler-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-new-playground-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/new-playground.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-new-playground-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-react-the-costs-of-accessibility-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/react-the-costs-of-accessibility.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-react-the-costs-of-accessibility-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-squidex-graphql-gatsby-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/squidex-graphql-gatsby.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-squidex-graphql-gatsby-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-styled-components-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/styled-components.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-styled-components-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-syntx-highlighting-gatsby-mdx-prismjs-mdx": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/posts/syntx-highlighting-gatsby-mdx-prismjs.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-src-content-posts-syntx-highlighting-gatsby-mdx-prismjs-mdx" */)
}

